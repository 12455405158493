.neomorphic-input {
    background: #f0f2f5;
    border-radius: 10px;
    box-shadow: 
        5px 5px 10px rgba(174, 174, 192, 0.4),
        -5px -5px 10px rgba(255, 255, 255, 0.9);
    border: none;
    transition: all 0.2s ease-in-out;
}

.neomorphic-input:focus {
    box-shadow: 
        inset 2px 2px 5px rgba(174, 174, 192, 0.4),
        inset -2px -2px 5px rgba(255, 255, 255, 0.9);
    outline: none;
}

.neomorphic-button {
    background: linear-gradient(145deg, #6366f1, #4f46e5);
    color: white;
    border-radius: 10px;
    box-shadow: 
        5px 5px 10px rgba(79, 70, 229, 0.2),
        -2px -2px 5px rgba(99, 102, 241, 0.2);
    border: none;
    transition: all 0.2s ease-in-out;
    position: relative;
    overflow: hidden;
}

.neomorphic-button:hover {
    background: linear-gradient(145deg, #4f46e5, #4338ca);
    transform: translateY(-2px);
    box-shadow: 
        7px 7px 15px rgba(79, 70, 229, 0.3),
        -2px -2px 5px rgba(99, 102, 241, 0.2);
}

.neomorphic-button:active {
    transform: translateY(0);
    box-shadow: 
        inset 2px 2px 5px rgba(67, 56, 202, 0.5),
        inset -2px -2px 5px rgba(99, 102, 241, 0.1);
}

.neomorphic-button::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(
        to bottom right,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0)
    );
    transform: rotate(45deg);
    pointer-events: none;
    z-index: 1;
    transition: all 0.6s ease-in-out;
    opacity: 0;
}

.neomorphic-button:hover::before {
    opacity: 1;
    transform: rotate(45deg) translateY(-50%);
}

.neomorphic-card {
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 
        10px 10px 20px rgba(174, 174, 192, 0.2),
        -10px -10px 20px rgba(255, 255, 255, 0.7);
    transition: all 0.3s ease-in-out;
}

.neomorphic-card:hover {
    box-shadow: 
        15px 15px 30px rgba(174, 174, 192, 0.3),
        -15px -15px 30px rgba(255, 255, 255, 0.8);
}

.genie-glow {
    position: relative;
}

.genie-glow::after {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background: radial-gradient(circle at center, rgba(138, 43, 226, 0.2) 0%, rgba(138, 43, 226, 0) 70%);
    z-index: -1;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.genie-glow:hover::after {
    opacity: 1;
}

.auth-container {
    min-height: 100vh;
    background-color: #f0f2f5;
}

.google-button {
    background: white;
    color: #757575;
    border-radius: 10px;
    box-shadow: 
        5px 5px 10px rgba(174, 174, 192, 0.4),
        -5px -5px 10px rgba(255, 255, 255, 0.9);
    border: none;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 10px 16px;
}

.google-button:hover {
    box-shadow: 
        7px 7px 15px rgba(174, 174, 192, 0.5),
        -7px -7px 15px rgba(255, 255, 255, 1);
}

.google-button:active {
    box-shadow: 
        inset 2px 2px 5px rgba(174, 174, 192, 0.4),
        inset -2px -2px 5px rgba(255, 255, 255, 0.9);
}

.divider {
    display: flex;
    align-items: center;
    text-align: center;
    color: #6b7280;
}

.divider::before,
.divider::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #e5e7eb;
}

.divider::before {
    margin-right: 1em;
}

.divider::after {
    margin-left: 1em;
}

.verification-container {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 16px;
    box-shadow: 
        inset 2px 2px 5px rgba(174, 174, 192, 0.4),
        inset -2px -2px 5px rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 16px;
} 